.status {
  &.online {
    color: COLOR(success);
  }

  &.offline {
    color: COLOR(alert-red-500);
    font-size: FONT_SIZES(medium);
  }
  &.warning {
    color: COLOR(warning);
    font-size: FONT_SIZES(medium);
  }
}

.warning-alert {
  i {
    svg {
      path {
        fill: COLOR(warning);
      }
    }
  }
}

.live-chart-icon {
  i {
    padding-top: 2px;
    svg {
      path {
        stroke: white;
      }
    }
  }
}
.live-tooltip {
  padding: 10px;
  left: 42px;
  top: 155px;
  font-size: 12px;
  max-width: 650px;
}
.historical-tooltip {
  padding: 10px;
  left: 42px;
  top: 155px;
  font-size: 12px;
  max-width: 625px !important;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #f9f9f9;
}
.p-tooltip .p-tooltip-text {
  border-radius: 12px;
  // width: 500px !important;
  white-space: nowrap !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  color: #2a2a57 !important;
}
