@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/corporate';
@import './styles/fonts';
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import './app/style-guide/breakpoints';
@import '../../../node_modules/primeicons/primeicons.css';
@import '../../../node_modules/primeng/resources/themes/saga-orange/theme.css';
@import '../../../node_modules/primeng/resources/primeng.min.css';

$nb-themes: nb-register-theme(
  (
    font-family-primary: unquote('Poppins, sans-serif'),
    // add your variables here like:
    // color-primary-100: #f2f6ff,
    // color-primary-200: #d9e4ff,
    // color-primary-300: #a6c1ff,
    // color-primary-400: #598bff,
    // color-primary-500: #3366ff,
    // color-primary-600: #274bdb,
    // color-primary-700: #1a34b8,
    // color-primary-800: #102694,
    // color-primary-900: #091c7a,,
    // color-primary-900: #091c7a,,,,,,
  ),
  corporate,
  corporate
);
.p-inputtext,
.p-inputnumber-input,
.p-dropdown,
.p-calendar,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b,
button,
input,
table,
thead,
tbody,
td,
th,
p,
label {
  font-family: 'Poppins', 'san-serif';
}
@mixin _prepend-with-selector($selector, $prop: null, $value: null) {
  #{$selector} & {
    @if $prop != null {
      #{$prop}: $value;
    }

    @content;
  }
}

@mixin nb-ltr($prop: null, $value: null) {
  @include _prepend-with-selector('[dir=ltr]', $prop, $value) {
    @content;
  }
}

@mixin nb-rtl($prop: null, $value: null) {
  @include _prepend-with-selector('[dir=rtl]', $prop, $value) {
    @content;
  }
}
::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-thumb {
  background: nb-theme(background-basic-color-5);
  cursor: pointer;
  border-radius: 0.4rem;
}

::-webkit-scrollbar-track {
  background: nb-theme(background-basic-color-5);
}
p-button {
  .p-button {
    font-family: 'Poppins', sans-serif;
  }
}
p-confirmDialog {
  .p-dialog {
    width: 45%;
    @include for-phone-only {
      width: calc(100% - 2rem) !important;
    }

    .p-confirm-dialog-message {
      @include nb-rtl(margin-left, unset);
      @include nb-rtl(margin-right, 1rem);
    }
    button {
      @include nb-rtl(margin, unset);
      @include nb-rtl(margin, 0 0 0 0.5rem);
      .p-button-icon-left {
        @include nb-rtl(margin-right, unset);
        @include nb-rtl(margin-left, 0.5rem);
      }
    }
    // .p-dialog-content {
    //   height: 100px;
    //   justify-content: center;
    //   align-items: center;
    // }
    // .p-dialog-footer {
    //   padding-top: 1.5rem;
    //   display: flex;
    //   justify-content: center;
    // }
  }
}
